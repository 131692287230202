import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import Typed from "react-typed"
import { server_url } from './url';

function Message() {

    const [message, setMessage] = useState<string>('');
    const [id_, setId] = useState<string>('');
    const { id } = useParams<{ id: string }>();


    useEffect(() => {
      fetch(`${server_url}/message/${id}`, {
        method: 'GET',
        redirect: 'follow'
      })
        .then(response => response.text())
        .then(result => {
          let data = JSON.parse(result);
          let message_result: string = data[0]['message'];
          let get: string = "";
          console.log(message_result);
          console.log(data);
          for (let i = 0; i < message_result.length; i++) {
            if(message_result[i] === '\n'){
              get += '<br />';
            } else {
              get += message_result[i];
            }

          }
          setMessage(get);
        })
        .catch(error => console.log('error', error));
        
        setId(id || '');
    }, [message, id_, id]);

  return (
    <div style={{marginTop: '5rem'}}>
                <p style={{fontSize: '30px', color: '#edf048', marginBottom: '0px', fontFamily: "'Chakra Petch', sans-serif"}}>    
                <Typed
                strings={[
                        message,
                    ]}
                    typeSpeed={150}
                    backSpeed={20}
                    loop
                    />            
                </p>
    </div>
  )
}

export default Message