import React, { useEffect } from 'react'    

function Home() {
    useEffect(() => {
    
    }, []);

  return (
    <div style={{marginTop: '10rem'}}>
    </div>
  )
}

export default Home