import React, {useState} from 'react'
import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import { server_url } from './url';

function Login() {

  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [error, setError] = useState<string>('');

  const handleUsername = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(e.target.value);
  }

  const handlePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  }

  const handleSubmit = () => {
    if (username === '' || password === '') {
      setError('Please fill in all fields');
    } else {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      var urlencoded = new URLSearchParams();
      urlencoded.append("username", username);
      urlencoded.append("password", password);

      var requestOptions: any = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
      };

      fetch(`${server_url}/login`, requestOptions)
        .then(response => response.text())
        .then(result => {
          if(result === "true") {
            localStorage.setItem("username", username);
            localStorage.setItem("password", password);
            window.location.href = '/genarate';
          } else {
            setError('Username or password is incorrect');
          }
        })
        .catch(error => console.log('error', error));
      setError('');
    }
  }

  return (
    <div style={{marginTop: '5rem', backgroundColor: '#fff', textAlign: 'center', padding: '3rem'}}>
      {error && <p style={{color: 'red'}}>{error}</p>}
      <h1>Login</h1>
        <TextField id="standard-basic" label="username" variant="standard" onChange={handleUsername} />
        <br />
        <TextField id="standard-basic" type="password" label="password" variant="standard" onChange={handlePassword} />
        <br />
        <br />
        <Button variant="contained" onClick={handleSubmit}>Login</Button>
    </div>
  )
}

export default Login