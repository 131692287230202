import React from 'react';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Container from '@mui/material/Container';

import Navbar from './components/Navbar';

import Home from './pages/Home';
import Message from './pages/Message';
import Genarate from './pages/Genarate';
import Login from './pages/Login';


function App() {
  return (

    <BrowserRouter>
    <Navbar />

    <Container  maxWidth="xl">
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="/message/:id" element={<Message />} />
      <Route path="/genarate" element={<Genarate />} />
      <Route path="*" element={<h1>Not Found</h1>} />
    </Routes>
    </Container>

    </BrowserRouter>


  );
}

export default App;
