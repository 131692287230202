import React, {useState, useRef, useEffect} from 'react'
import { QRCodeCanvas } from "qrcode.react";
import { v4 as uuidv4 } from 'uuid';
import { sha256 } from 'js-sha256';
import { server_url, client_url } from './url';

function Genarate() {
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [code, setCode] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    setUsername(localStorage.getItem('username') || "");
    setPassword(localStorage.getItem('password') || "");

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("username", localStorage.getItem('username'));
    urlencoded.append("password", localStorage.getItem('password'));

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };

    fetch(`${server_url}/login`, requestOptions)
      .then(response => response.text())
      .then(result => {
        if(result === "false"){
          localStorage.removeItem("username");
          localStorage.removeItem("password");
          window.location.href = "/login";
        }
        
      })
      .catch(error => console.log('error', error));

    var hash = sha256.create();
    hash.update(uuidv4());
    hash.hex();
    setCode(hash);
  }, [username, password]);

  const qrRef = useRef();
  const downloadQRCode = (e) => {
    e.preventDefault();
    let canvas = qrRef.current.querySelector("canvas");
    let image = canvas.toDataURL("image/png");
    let anchor = document.createElement("a");
    anchor.href = image;
    anchor.download = `qr-code.png`;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
    
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    
    var urlencoded = new URLSearchParams();
    urlencoded.append("message", message);
    urlencoded.append("name", name);
    urlencoded.append("code", code);
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };
    
    fetch(`${server_url}/create`, requestOptions)
      .then(response => response.text())
      .then(result => console.log(result))
      .catch(error => console.log('error', error));

    setName("");
    setMessage("");
    setCode("");
    alert("สร้าง QR Code สำเร็จ");
    window.location.reload();
  };
  const qrCodeEncoder = (e) => {
    setName(e.target.value);
  };


  const qrcode = (
    <QRCodeCanvas
      id="qrCode"
      value={`${client_url}/message/`+code}
      size={300}
      bgColor={"#fff"}
      level={"H"}
    />
  );

  const handleMessage = (e) => { 
    setMessage(e.target.value);
  }

  return (
    <div style={{marginTop: '10rem', width: '100%'}}>
    <div ref={qrRef} style={{marginLeft: 'auto', marginRight: 'auto', width: '300px', backgroundColor: '#fff'}}>{qrcode}</div>
      <div style={{marginLeft: 'auto', marginRight: 'auto', backgroundColor: '#fff', padding: '10px', marginTop: '10px'}}>
        <form onSubmit={downloadQRCode} >
          <label className='form-control'>ชื่อ</label>
          <br />
          <input
          className='form-control'
          style={{width: '100%'}}
            type="text"
            value={name}
            onChange={qrCodeEncoder}
            placeholder="Enter your name"
          />
          <br />
          <label className='form-control'>ข้อความ</label>
          <br />
          <textarea className='form-control' style={{width: '100%'}} rows="10" onChange={handleMessage}>{message}</textarea>
          <br />
          <button type="submit" disabled={!name}>
            Download QR code
          </button>
        </form>
      </div>
    </div>
  )
}

export default Genarate